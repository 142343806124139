<template>
  <div class="container page-welcome">
    <div class="row">
      <div class="col-sm-offset-1 col-sm-10 col-md-6 col-md-offset-3">
        <!-- <strong class="h4 text-secondary">Getting started</strong> -->
        <div class="card relative m-b-25">
          <em class="title m-b-25" v-if="user">Welcome, {{ user.firstName || user.lastName }}!</em>
          <p class="m-b-5">Use Swoop's funding search facility for your clients:</p>
          <p class="m-b-5">
            - Launching a funding search allows you to see funding solutions that match your clients business across loans, equity, grants, {{ $dictionary.taxCreditLabel }} tax
            credits or savings solutions.
          </p>
          <p class="m-b-5">- Select the loans, equity, grants, {{ $dictionary.taxCreditLabel }} tax credits or savings funds you want to review for your clients.</p>
        </div>
        <div class="text-center">
          <router-link class="btn btn-sm btn-primary" :to="{ name: 'journey-funding-requirements' }">
            Start funding search for your client
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { authGetters } from '@/store/modules/auth/routines'

export default {
  name: 'JourneyWelcome',
  computed: {
    ...mapGetters({
      user: authGetters.USER
    })
  }
}
</script>
